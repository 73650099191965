import { AfficheError, getNbSlots } from '../utils/utils'

document.addEventListener("turbo:load", function() {
    let minuteIncrement;

    if (document.querySelector('#slotshours')) {
      const nbslots = getNbSlots();
      document.querySelector(':root').style.setProperty('--numberDivHours', parseInt(nbslots));
      minuteIncrement = (24 * 60)/parseInt(nbslots);
    }

    [].forEach.call(document.querySelectorAll('.flatpickr-start'), function(el) {
          el.addEventListener('change', function() {

            document.querySelector('#diverreurclient').classList.add("hidden");
            const startDateValue = this.querySelector('.flatpickr-input').value;
            const minutesDateDebut = startDateValue.slice(-2);
            if ( startDateValue &&  ((parseInt(minutesDateDebut) % minuteIncrement) != 0) ) {
              const startDateDebut = startDateValue.slice(0,-2) + '00';
              const startFlatpickr = this._flatpickr;
              startFlatpickr.setDate(startDateDebut);
              const libelle = `Erreur lors la saisie de la date de début. Les minutes doivent être des multiples de ${minuteIncrement}.`
              AfficheError(libelle);
              return;
            }

            const endFlatpickr = this.parentElement.nextElementSibling.querySelector('.flatpickr-end')._flatpickr;
            const selectEndDates = endFlatpickr.selectedDates;

            if ( startDateValue && selectEndDates.length === 0 ) {
              const endDateValue = new Date(startDateValue);
              endDateValue.setMinutes(endDateValue.getMinutes() + minuteIncrement)
              endFlatpickr.setDate(endDateValue);
              endFlatpickr.set('minDate', endDateValue);
            };

            if (!startDateValue){
              endFlatpickr.set('minDate', "");
            }

            if ( startDateValue && selectEndDates.length !== 0 ) {
              const startDate = new Date(startDateValue);
              
              const endDateValue = new Date(startDateValue);
              endDateValue.setMinutes(endDateValue.getMinutes() + minuteIncrement)

              const endDate = new Date(selectEndDates[0]);
              if ( endDate <= startDate ) {
                endFlatpickr.setDate(endDateValue);
              }
              endFlatpickr.set('minDate', endDateValue);
            };

          });
});

    [].forEach.call(document.querySelectorAll('.flatpickr-end'), function(el) {
          el.addEventListener('change', function() {

            document.querySelector('#diverreurclient').classList.add("hidden");
            const endDateValue = this.querySelector('.flatpickr-input').value;
            const minutesDateFin = endDateValue.slice(-2);
            if ( endDateValue && ((parseInt(minutesDateFin) % minuteIncrement) != 0) ) {
              const startDateFin = endDateValue.slice(0,-2) + '00';
              const endFlatpickr = this._flatpickr;
              endFlatpickr.setDate(startDateFin);
              const libelle = `Erreur lors la saisie de la date de fin. Les minutes doivent être des multiples de ${minuteIncrement}.`
              AfficheError(libelle);
              return;
            }
            const startFlatpickr = this.parentElement.previousElementSibling.querySelector('.flatpickr-start')._flatpickr;
            const selectStartDates = startFlatpickr.selectedDates;

            if ( endDateValue && selectStartDates.length === 0 ) {
              startFlatpickr.setDate(endDateValue);
              startFlatpickr.set('maxDate', endDateValue);
            };

            if (!endDateValue){
              startFlatpickr.set('maxDate', "");
            }

            if ( endDateValue && selectStartDates.length !== 0 ) {
              const startDate = new Date(selectStartDates[0]);
              const endDate = new Date(endDateValue);

              if ( endDate < startDate ) {
                startFlatpickr.setDate(endDateValue);
              }
              startFlatpickr.set('maxDate', endDateValue);
            };
          });
    })

});

document.addEventListener("turbo:render", function() {
    let minuteIncrement;

    if (document.querySelector('#slotshours')) {
      const nbslots = getNbSlots();
      document.querySelector(':root').style.setProperty('--numberDivHours', parseInt(nbslots));
      minuteIncrement = (24 * 60)/parseInt(nbslots);
    }

    [].forEach.call(document.querySelectorAll('.flatpickr-start'), function(el) {
          el.addEventListener('change', function() {

            document.querySelector('#diverreurclient').classList.add("hidden");
            const startDateValue = this.querySelector('.flatpickr-input').value;
            const minutesDateDebut = startDateValue.slice(-2);
            if ( startDateValue &&  ((parseInt(minutesDateDebut) % minuteIncrement) != 0) ) {
              const startDateDebut = startDateValue.slice(0,-2) + '00';
              const startFlatpickr = this._flatpickr;
              startFlatpickr.setDate(startDateDebut);
              const libelle = `Erreur lors la saisie de la date de début. Les minutes doivent être des multiples de ${minuteIncrement}.`
              AfficheError(libelle);
              return;
            }

            const endFlatpickr = this.parentElement.nextElementSibling.querySelector('.flatpickr-end')._flatpickr;
            const selectEndDates = endFlatpickr.selectedDates;

            if ( startDateValue && selectEndDates.length === 0 ) {
              const endDateValue = new Date(startDateValue);
              endDateValue.setMinutes(endDateValue.getMinutes() + minuteIncrement)
              endFlatpickr.setDate(endDateValue);
              endFlatpickr.set('minDate', endDateValue);
            };

            if (!startDateValue){
              endFlatpickr.set('minDate', "");
            }

            if ( startDateValue && selectEndDates.length !== 0 ) {
              const startDate = new Date(startDateValue);
              
              const endDateValue = new Date(startDateValue);
              endDateValue.setMinutes(endDateValue.getMinutes() + minuteIncrement)

              const endDate = new Date(selectEndDates[0]);
              if ( endDate <= startDate ) {
                endFlatpickr.setDate(endDateValue);
              }
              endFlatpickr.set('minDate', endDateValue);
            };

          });
});

    [].forEach.call(document.querySelectorAll('.flatpickr-end'), function(el) {
          el.addEventListener('change', function() {

            document.querySelector('#diverreurclient').classList.add("hidden");
            const endDateValue = this.querySelector('.flatpickr-input').value;
            const minutesDateFin = endDateValue.slice(-2);
            if ( endDateValue && ((parseInt(minutesDateFin) % minuteIncrement) != 0) ) {
              const startDateFin = endDateValue.slice(0,-2) + '00';
              const endFlatpickr = this._flatpickr;
              endFlatpickr.setDate(startDateFin);
              const libelle = `Erreur lors la saisie de la date de fin. Les minutes doivent être des multiples de ${minuteIncrement}.`
              AfficheError(libelle);
              return;
            }
            const startFlatpickr = this.parentElement.previousElementSibling.querySelector('.flatpickr-start')._flatpickr;
            const selectStartDates = startFlatpickr.selectedDates;

            if ( endDateValue && selectStartDates.length === 0 ) {
              startFlatpickr.setDate(endDateValue);
              startFlatpickr.set('maxDate', endDateValue);
            };

            if (!endDateValue){
              startFlatpickr.set('maxDate', "");
            }

            if ( endDateValue && selectStartDates.length !== 0 ) {
              const startDate = new Date(selectStartDates[0]);
              const endDate = new Date(endDateValue);

              if ( endDate < startDate ) {
                startFlatpickr.setDate(endDateValue);
              }
              startFlatpickr.set('maxDate', endDateValue);
            };
          });
    })

});
