document.addEventListener("turbo:load", function() {
    [].forEach.call(document.querySelectorAll('#btnnouveaupensebete'), function(el) {
          el.addEventListener('click', function(event) {

            document.querySelector('#btnnouveaupensebete').classList.add('hidden');
            document.querySelector('#btncancelnouveaupensebete').classList.remove('hidden');
            document.querySelector('#newmemoform').classList.remove('hidden');
            const divContainerNotice = document.querySelector('#containernoticeinsert');
            while (divContainerNotice.firstChild) {
              divContainerNotice.removeChild(divContainerNotice.firstChild);
            }
          });
    });

    [].forEach.call(document.querySelectorAll('#btncancelnouveaupensebete'), function(el) {
          el.addEventListener('click', function(event) {

            document.querySelector('#btnnouveaupensebete').classList.remove('hidden');
            document.querySelector('#btncancelnouveaupensebete').classList.add('hidden');
            document.querySelector('#newmemoform').classList.add('hidden');
            document.querySelector('#memo_title').value='';
            document.querySelector('#memo_content').value='';
            if (document.querySelector('#error_explanation')){
              document.querySelector('#error_explanation').remove()
            };
            if (document.querySelector('.alert')){
              document.querySelector('.alert').remove()
            };
            [].forEach.call(document.querySelectorAll('.field_with_errors'), function(el) {
                el.classList.remove('field_with_errors');
            });
            const divContainerNotice = document.querySelector('#containernoticeinsert');
            while (divContainerNotice.firstChild) {
              divContainerNotice.removeChild(divContainerNotice.firstChild);
            }
          });
    });

    [].forEach.call(document.querySelectorAll('.deletememolink'), function(el) {
      el.addEventListener('click', function(event) {
        console.log("ds function");
        const divContainerNotice = document.querySelector('#containernoticedestroy');
        while (divContainerNotice.firstChild) {
          divContainerNotice.removeChild(divContainerNotice.firstChild);
        }
      });
    });
});


document.addEventListener("turbo:submit-start", function() {
  
  const divError = document.querySelector('#error_explanation');
  if ( divError ) { divError.remove(); }
  
  [].forEach.call(document.querySelectorAll('.field_with_errors'), function(el) {
      el.classList.remove("field_with_errors");
  });

});
