import {cumulativeOffset}  from '../utils/utils';

document.addEventListener("turbo:load", function() {
  let timeOuntId;
  [].forEach.call(document.querySelectorAll('.meeting-affichage-visible-weekly'), function(el) {
        el.addEventListener('mouseenter', function(event) {

          const absoluteOffset = cumulativeOffset($(this)[0]);
          const hiddenDiv = $(this)[0].nextElementSibling;
          const libelle = $(this)[0].querySelector('.meeting-affichage-visible-span-hour').innerText;
          const dateDebut = libelle.slice(0, libelle.indexOf("-")).trim();
          const dateFin = libelle.slice(libelle.indexOf("-") + 1).trim();
          hiddenDiv.querySelector('.heuredebuthiddendiv').innerText = dateDebut;
          hiddenDiv.querySelector('.heurefinhiddendiv').innerText = dateFin;

          const positionY = Number($(this).css( 'top').slice(0,-2));
          const positionLeft = Number($(this).css( 'left').slice(0,-2));
          const hiddenDivWidth = Number(window.getComputedStyle(hiddenDiv).width.slice(0,-2));
          const hiddenDivHeight = Number(window.getComputedStyle(hiddenDiv).height.slice(0,-2));
          const eventWidth = Number($(this).css( 'width').slice(0,-2));

          let affichageY = positionY - 50;
          let affichageX = positionLeft + eventWidth/2;

          if ( event.clientX >  (window.screen.width/2)) {
            affichageX = affichageX - hiddenDivWidth;
          }

          if (event.clientY >  (window.screen.height/2)) {
            affichageY = affichageY - hiddenDivHeight/2;
          }

          timeOuntId = setTimeout(function() {
            hiddenDiv.style.opacity = "1";
            hiddenDiv.style.top = affichageY + 'px';
            hiddenDiv.style.left = affichageX + 'px';
            hiddenDiv.style.zIndex = "250";
          }, 1000);


        });

        el.addEventListener('mouseleave', function(event) {

          clearTimeout(timeOuntId);
          const hiddenDiv = $(this)[0].nextElementSibling;
          hiddenDiv.style.opacity = "0";
          hiddenDiv.style.top = '-1500px';
          hiddenDiv.style.left = '-1500px';
          hiddenDiv.style.zIndex = "0";

        });
});


});
