// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    document.querySelector('#memo_title').value='';
    document.querySelector('#memo_content').value='';

    const divOperation = document.querySelector('#operation');
    if (divOperation){
        const operation = divOperation.dataset.operation;
        const libelle = divOperation.dataset.libelle;

        if (operation == "INSERT"){
            const divContainerNotice = document.querySelector('#containernoticeinsert');
            const divNotice = `
            <div id="noticedestroy" class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>${libelle} </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            `
            while (divContainerNotice.firstChild) {
                divContainerNotice.removeChild(divContainerNotice.firstChild);
            }
            divContainerNotice.insertAdjacentHTML('beforeend', divNotice);
        }

        if (operation == "DESTROY"){
            const divContainerNotice = document.querySelector('#containernoticedestroy');
            const divNotice = `
            <div id="noticedestroy" class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>${libelle} </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            `
            while (divContainerNotice.firstChild) {
                divContainerNotice.removeChild(divContainerNotice.firstChild);
            }
            divContainerNotice.insertAdjacentHTML('beforeend', divNotice);
            divContainerNotice.focus();
        }
    }

    [].forEach.call(document.querySelectorAll('.deletememolink'), function(el) {
        el.addEventListener('click', function(event) {
            const divContainerNotice = document.querySelector('#containernoticedestroy');
            while (divContainerNotice.firstChild) {
                divContainerNotice.removeChild(divContainerNotice.firstChild);
            }
        });
    });

    var memoTableHandle;
    memoTableHandle = $('#listmemos').dataTable({
        "destroy": true,
        "lengthMenu": [[5, 15, 25, 50, -1], [5, 15, 25, 50, "Toutes"]],
        "iDisplayLength": 15,
        "pagingType": "full_numbers",
        "order": [[0, "desc"]],
        "aoColumnDefs": [{
            bSortable: false,
            aTargets: ['nosort']
        }],
        language: {
            "sProcessing": "Traitement en cours...",
            "sSearch": "",
            "sSearchPlaceholder": "Rechercher",
            "sLengthMenu": "Afficher _MENU_ pense-bêtes",
            "sInfo": "Affichage du pense-bête _START_ &agrave; _END_ sur _TOTAL_ pense-bêtes",
            "sInfoEmpty": "Affichage du pense-bête 0 &agrave; 0 sur 0 pense-bêtes",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ pense-bêtes au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun pense-bête &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
                "sFirst": "Premier",
                "sPrevious": "Pr&eacute;c&eacute;dent",
                "sNext": "Suivant",
                "sLast": "Dernier"
            },
            "oAria": {
                "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            }
        }
    });
  }

}
