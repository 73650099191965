import { Controller } from "@hotwired/stimulus"
import { debounce } from '../../javascript/packs/utils/utils'

export default class extends Controller {
    connect() {

    }

    updateContent(e) {
        const memoId = e.target.dataset.id
        const updatedContent = this.element.innerHTML.trim().replace(/\n/g, '<br>');
        const errorDiv = this.element.parentElement.querySelector('.memocontenterror');
        errorDiv.style.display = "none";

        const url = `/updatememocontent?id=${memoId}&content=${updatedContent}`
        fetch( url)
        .then(response => response.json())
        .then(res => {
            if (res.status == 404) {
                errorDiv.innerHTML = res.error;
                errorDiv.style.display = "block";
                this.element.focus();
            } else if (res.status == 405) {
                errorDiv.innerHTML = 'Description ' + res.errors.content
                errorDiv.style.display = "block";
                this.element.focus();
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            errorDiv.innerHTML = "Une erreur s'est produite. Contacter votre administrateur.";
            errorDiv.style.display = "block";
        });
    }

    updateServer = debounce((e) => this.updateContent(e));
}
