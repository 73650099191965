document.addEventListener("turbo:load", function() {
    var eventTableHandle;
    eventTableHandle = $('#listmeetings').dataTable({
        "lengthMenu": [[5, 15, 25, 50, -1], [5, 15, 25, 50, "Toutes"]],
        "iDisplayLength": 15,
        "pagingType": "full_numbers",
        "order": [[2, "desc"]],
        "aoColumnDefs": [{
            bSortable: false,
            aTargets: [ -1 ]
        }],
        language: {
            "sProcessing": "Traitement en cours...",
            "sSearch": "",
            "sSearchPlaceholder": "Rechercher",
            "sLengthMenu": "Afficher _MENU_ meetings",
            "sInfo": "Affichage du meeting _START_ &agrave; _END_ sur _TOTAL_ meetings",
            "sInfoEmpty": "Affichage du meeting 0 &agrave; 0 sur 0 meetings",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ meetings au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun meeting &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
                "sFirst": "Premier",
                "sPrevious": "Pr&eacute;c&eacute;dent",
                "sNext": "Suivant",
                "sLast": "Dernier"
            },
            "oAria": {
                "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            }
        }
    });

    var memoTableHandle;
    memoTableHandle = $('#listmemos').dataTable({
        "destroy": true,
        "lengthMenu": [[5, 15, 25, 50, -1], [5, 15, 25, 50, "Toutes"]],
        "iDisplayLength": 15,
        "pagingType": "full_numbers",
        "order": [[0, "desc"]],
        "aoColumnDefs": [{
            bSortable: false,
            aTargets: ['nosort']
        }],
        language: {
            "sProcessing": "Traitement en cours...",
            "sSearch": "",
            "sSearchPlaceholder": "Rechercher",
            "sLengthMenu": "Afficher _MENU_ pense-bêtes",
            "sInfo": "Affichage du pense-bête _START_ &agrave; _END_ sur _TOTAL_ pense-bêtes",
            "sInfoEmpty": "Affichage du pense-bête 0 &agrave; 0 sur 0 pense-bêtes",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ pense-bêtes au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun pense-bête &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
                "sFirst": "Premier",
                "sPrevious": "Pr&eacute;c&eacute;dent",
                "sNext": "Suivant",
                "sLast": "Dernier"
            },
            "oAria": {
                "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            }
        }
    });
});
