import { French } from "flatpickr/dist/l10n/fr.js";
import { getNbSlots } from '../utils/utils'

document.addEventListener("turbo:load", () => {

    let minuteIncrement;
    if (document.querySelector('#slotshours')) {
      const nbslots = getNbSlots();
      minuteIncrement = (24 * 60)/parseInt(nbslots);
    };

    flatpickr(".flatpickr", {
      enableTime: true,
      altFormat: "l j F, Y H:i",
      altInput: true,
      "locale": French,
      time_24hr: true,
      monthSelectorType: "static",
      wrap: true,
      minuteIncrement: minuteIncrement,
      clickOpens: false,
      closeOnSelect: true,
      position: "auto right",
      allowInput: false,
    });
});

document.addEventListener("turbo:render", () => {

    let minuteIncrement;
    if (document.querySelector('#slotshours')) {
      const nbslots = getNbSlots();
      minuteIncrement = (24 * 60)/parseInt(nbslots);
    };

    flatpickr(".flatpickr", {
      enableTime: true,
      altFormat: "l j F, Y H:i",
      altInput: true,
      "locale": French,
      time_24hr: true,
      monthSelectorType: "static",
      wrap: true,
      minuteIncrement: minuteIncrement,
      clickOpens: false,
      closeOnSelect: true,
      position: "auto right",
      allowInput: false,
    });
});
