import { Controller } from "@hotwired/stimulus"
import { debounce } from '../packs/utils/utils'
export default class extends Controller {
    connect() {

    }

    updateTitle(e) {
        const divElement = e.target.parentElement.parentElement;
        const memoId = divElement.dataset.id
        const updatedTitle = e.target.value.trim()
        const errorDiv = this.element.parentElement.querySelector('.memotitleerror');
        // errorDiv.style.display = "none";

        const url = `/updatememotitle?id=${memoId}&title=${updatedTitle}`
        fetch( url)
        .then(response => response.json())
        .then(res => {
            if (res.status == 404) {
                errorDiv.innerHTML = res.error;
                errorDiv.style.display = "block";
                this.element.focus();
            } else if (res.status == 405) {
                errorDiv.innerHTML = 'Titre ' + res.errors.title
                errorDiv.style.display = "block";
                this.element.focus();
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            errorDiv.innerHTML = "Une erreur s'est produite. Contacter votre administrateur.";
            errorDiv.style.display = "block";
        });
    }

    updateServer = debounce((e) => this.updateTitle(e));

}
