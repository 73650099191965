import {AfficheError, getNbSlots} from '../utils/utils';

document.addEventListener("turbo:load", function() {
    let oldHeight, minDuree, nbslots, originalLedtPos, originalWidth;
    if (document.querySelector('#slotshours')) {
        nbslots = parseInt(getNbSlots());
      }
    $( ".is-resizable" ).resizable({
        containment: "parent",
        handles: 's',
        start: function(event,ui) {
            originalWidth = ui.originalSize.width;
            originalLedtPos = ui.position.left;
            document.querySelector('#diverreurclient').classList.add("hidden");
            minDuree =  Number($(this).css( 'min-height').slice(0, -2));
            $(this).resizable({ minHeight:  minDuree});
            oldHeight = Number($(this).css( 'height').slice(0, -2));
            const posTop = Number($(this).css( 'top').slice(0, -2));
            const allPostionTop = [];
            $(this).parent().find(".is-resizable").each(function( index ) {
                if ( Number($( this ).position().top) > posTop + minDuree ) {
                    allPostionTop.push(Number($( this ).position().top));
                }
            });
            const closestEventTop = allPostionTop.length > 0 ? Math.min(...allPostionTop) : null;
            if (closestEventTop) {
                $(this).resizable({ maxHeight:  closestEventTop - posTop - 1});
            } else {
                $(this).resizable({ maxHeight: (nbslots * minDuree) - posTop - 1 });
            }

        },
        resize: function(event, ui){
            ui.position.left = originalLedtPos;
            $(this).css('width','100%');

        },
        stop: function(event, ui){
            const eventId = $(this)[0].dataset.id;
            const url = "/resizableupdate";
            const resizableDiv = $(this);
            const duree = Math.ceil(ui.size.height/minDuree);
            const newHeight = Math.ceil(ui.size.height/minDuree) * minDuree;
            $.ajax({
                url: url,
                data : {
                    id: eventId,
                    duree: duree,
                },
                success: function(data) {
                    if (data.status == 200) {
                        resizableDiv.height(newHeight);
                        resizableDiv[0].querySelector('span').innerText = data.title;
                    } else {
                        console.log(data);
                        AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.')
                        resizableDiv.height(oldHeight);
                    }
                },
                error: function() {
                    console.log('failure');
                    resizableDiv.height(oldHeight);
                    AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.');
                }
            });

        }
    });
});
