// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery-ui/javascript/jquery-ui")
require("jquery-ui/javascript/jquery-collision.min")
require("jquery-ui/javascript/jquery-ui-draggable-collision.min")
require("datatables/javascript/jquery-3.5.1")
require("datatables/javascript/jquery.dataTables.min")
import flatpickr from "flatpickr";

import {getNbSlots} from './utils/utils';
import * as bootstrap from 'bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
import { right } from "@popperjs/core";
import "@hotwired/turbo-rails"

import "../controllers"

require("jquery-init");
require("flatpickr-init");
require("flatpickr-change-listener");
require("datatables-init");
require("jquery-ui-resizable");
require("jquery-ui-draggable");
require("jquery-ui-draggable-weekly");
require("daylyview");
require("weeklyview");
require("./utils/newMemo");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from "@hotwired/turbo-rails"


document.addEventListener("turbo:load", () => {

    if (document.querySelector('#slotshours')) {
        const nbslots = getNbSlots();
        document.querySelector(':root').style.setProperty('--numberDivHours', parseInt(nbslots));
    }

});

import "controllers"
