import {AfficheError, cumulativeOffset, getNbSlots, getPosJour , findDaySlot}  from '../utils/utils';

document.addEventListener("turbo:load", function() {
    let oldPositionTop, oldPositionLeft, minDuree , offsetLeft;
    let divHeight, minLeftPos,maxLeftPos, endPos;
    const nbslots = getNbSlots();
    $(".is-draggable-week").draggable({
        preventCollision: true,
        obstacle:".is-draggable",
        cursor: "move",
        start: function(event,ui) {
            document.querySelector('#diverreurclient').classList.add("hidden");
            const posjour = getPosJour();
            offsetLeft = cumulativeOffset($(this)[0]).left;
            minLeftPos = posjour.min - offsetLeft + ui.position.left;
            maxLeftPos = posjour.max - offsetLeft + ui.position.left;
            divHeight = Number($(this).css( 'height').slice(0, -2));
            minDuree =  Number($(this).css( 'min-height').slice(0, -2));
            oldPositionTop = Number($(this).css( 'top').slice(0, -2));
            oldPositionLeft = ui.position.left;
            $(this).removeClass('is-draggable');

        },
        drag: function(event,ui) {
            if (ui.position.left < minLeftPos) { ui.position.left = minLeftPos}
            if ( ui.position.top < 0) { ui.position.top = 0}
            const maxFromTop = nbslots * minDuree - divHeight;
            if ( ui.position.left > maxLeftPos ) { ui.position.left = maxLeftPos }
            if (ui.position.top > maxFromTop ) { ui.position.top = maxFromTop}

        },
        stop: function(event,ui) {
            $(this).addClass('is-draggable');
            const eventId = $(this)[0].dataset.id;
            const url = "/draggableupdateweekly";
            const draggableDiv = $(this);
            const duree = Math.ceil(divHeight/minDuree);
            const absoluteLeftPos = cumulativeOffset($(this)[0]).left;
            endPos = findDaySlot(absoluteLeftPos);
            draggableDiv.css( 'top', Math.floor(ui.position.top/minDuree) * minDuree );
            const posLeft =  endPos.posLeft - offsetLeft + oldPositionLeft;
            draggableDiv.css('left', posLeft );
            const distancefromtop = Math.floor(ui.position.top/minDuree) ;
            const decalage = endPos.decalageLeft;

            $.ajax({
                url: url,
                data : {
                    id: eventId,
                    distancefromtop: distancefromtop,
                    duree: duree,
                    jour: decalage,
                },
                success: function(data) {
                    if (data.status == 200) {
                        let oldClass='decalage-top-'+ (oldPositionTop/minDuree);
                        const newClass= 'decalage-top-'+ Math.floor(ui.position.top/minDuree);
                        draggableDiv.removeClass(oldClass);
                        draggableDiv.addClass(newClass);
                        draggableDiv.css( 'top', Math.floor(ui.position.top/minDuree) * minDuree );
                        draggableDiv.css('left', posLeft );
                        draggableDiv[0].querySelector('span').innerText = data.title;
                    } else {
                        console.log(data);
                        AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.');
                        draggableDiv.css( 'top', oldPositionTop);
                        draggableDiv.css( 'left', oldPositionLeft );
                    }
                },
                error: function() {
                    console.log('failure');
                    draggableDiv.css( 'top', oldPositionTop );
                    draggableDiv.css( 'left', oldPositionLeft );
                    AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.');
                }
            });
        }
    });
});
