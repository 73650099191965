
document.addEventListener("turbo:load", function() {
    let timeOuntId;
    [].forEach.call(document.querySelectorAll('.meeting-affichage-visible-dayly'), function(el) {
          el.addEventListener('mouseenter', function(event) {

            const hiddenDiv = $(this)[0].nextElementSibling;
            const libelle = $(this)[0].querySelector('.meeting-affichage-visible-span-hour').innerText;
            const dateDebut = libelle.slice(0, libelle.indexOf("-")).trim();
            const dateFin = libelle.slice(libelle.indexOf("-") + 1).trim();
            hiddenDiv.querySelector('.heuredebuthiddendiv').innerText = dateDebut;
            hiddenDiv.querySelector('.heurefinhiddendiv').innerText = dateFin;

            const positionY = Number($(this).css( 'top').slice(0,-2));

            const positionLeft = Number($(this).css( 'left').slice(0,-2));
            const width = Number($(this).css( 'width').slice(0,-2));
            let affichageY = positionY - 50;
            const affichageX = positionLeft + width/2;

            if (affichageY > 1200) { affichageY = affichageY - 50 }
            timeOuntId = setTimeout(function() {
              hiddenDiv.style.opacity = "1";
              hiddenDiv.style.top = affichageY + 'px';
              hiddenDiv.style.left = affichageX + 'px';
              hiddenDiv.style.zIndex = "250";
            }, 1000);


          });

          el.addEventListener('mouseleave', function(event) {

            clearTimeout(timeOuntId);
            const hiddenDiv = $(this)[0].nextElementSibling;
            hiddenDiv.style.opacity = "0";
            hiddenDiv.style.top = '-1500px';
            hiddenDiv.style.left = '-1500px';
            hiddenDiv.style.zIndex = "0";

          });
});


});
