import {AfficheError, getNbSlots} from '../utils/utils';

document.addEventListener("turbo:load", function() {
    let oldPositionTop, minDuree, divHeight;
    const nbslots = getNbSlots();
    $(".is-draggable-day").draggable({
        axis: "y",
        preventCollision: true,
        obstacle:".is-draggable",
        cursor: "move",
        start: function(event,ui) {
            document.querySelector('#diverreurclient').classList.add("hidden");
            divHeight = Number($(this).css( 'height').slice(0, -2));
            minDuree =  Number($(this).css( 'min-height').slice(0, -2));
            oldPositionTop = Number($(this).css( 'top').slice(0, -2));
            $(this).removeClass('is-draggable');

        },
        drag: function(event,ui) {
            ui.position.left = 0;
            const maxFromTop = nbslots * minDuree - divHeight;
            if (ui.position.top < 0 ) { ui.position.top = 0}
            if (ui.position.top > maxFromTop ) { ui.position.top = maxFromTop}

        },
        stop: function(event,ui) {
            $(this).addClass('is-draggable');
            const eventId = $(this)[0].dataset.id;
            const url = "/draggableupdate";
            const resizableDiv = $(this);
            const duree = Math.ceil(divHeight/minDuree);
            const distancefromtop = Math.floor(ui.position.top/minDuree) ;

            $.ajax({
                url: url,
                data : {
                    id: eventId,
                    duree: duree,
                    distancefromtop: distancefromtop,
                },
                success: function(data) {
                    if (data.status == 200) {
                        let oldClass='decalage-top-'+ (oldPositionTop/minDuree);
                        const newClass= 'decalage-top-'+ Math.floor(ui.position.top/minDuree);
                        resizableDiv.removeClass(oldClass);
                        resizableDiv.addClass(newClass);
                        resizableDiv.css( 'top', Math.floor(ui.position.top/minDuree) * minDuree );
                        resizableDiv[0].querySelector('span').innerText = data.title;
                    } else {
                        console.log(data);
                        AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.');
                        resizableDiv.css( 'top', oldPositionTop);
                    }
                },
                error: function() {
                    console.log('failure');
                    resizableDiv.css( 'top', oldPositionTop );
                    AfficheError('Une erreur est survenue. Veuillez essayer ultérieurement.');
                }
            });
        }
    });
});
