export const AfficheError = (libelle) => {
    const div = document.querySelector('#diverreurclient');
    div.classList.add("hidden");
    const listViewItem=document.createElement('li');
    listViewItem.innerHTML=libelle;
    if (document.querySelector('#ulerreurclient > li')) {
        document.querySelector('#ulerreurclient > li').remove()
    }
    document.querySelector('#ulerreurclient').appendChild(listViewItem);
    div.classList.remove("hidden");
};

export const cumulativeOffset = function(element) {
    var top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);

    return {
        top: top,
        left: left
    };
};

export const getNbSlots = function() {
    let nbslots = 48;
    if (document.querySelector('#slotshours')) {
        nbslots = parseInt(document.querySelector('#slotshours').dataset.userNbslots);
    }
    return nbslots;
};

export const getPosJour = function() {
    const allPostionLeft = [];
    $(".weekly-view-jour-td").each(function( index ) {
        const offSet = cumulativeOffset($(this)[0]);
        allPostionLeft.push(Number(offSet.left));
    });
    const minLeft = Math.min(...allPostionLeft);
    const maxLeft = Math.max(...allPostionLeft);

    return {
        min: minLeft,
        max: maxLeft
    };

};

export const findDaySlot = function(pos) {
    const allPostionLeft = [];
    $(".weekly-view-jour-td").each(function( index ) {
        const offSet = cumulativeOffset($(this)[0]);
        allPostionLeft.push(Number(offSet.left));
    });

    let slot, absolutePos
    allPostionLeft.sort((a,b) => a-b);
    for (let index = 0; index < allPostionLeft.length-1; index++) {
        if ( pos >= allPostionLeft[index] && pos < allPostionLeft[index+1]) {
            slot = index + 1;
            absolutePos = allPostionLeft[index];
        }
        if (pos >= allPostionLeft[allPostionLeft.length-1]) {
            slot = allPostionLeft.length;
            absolutePos = allPostionLeft[allPostionLeft.length-1];
        }
    }

    return {
        decalageLeft: slot,
        posLeft: absolutePos
    };

};

export const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}
